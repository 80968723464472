import React, {useContext, useState} from 'react';
import { Link, Redirect } from 'react-router-dom';

import i18n, {getLanguageDependendContent, getLanguageDependendPermalink} from '../../helpers/i18n';
import StoreContext from '../../storeContext';

const Footer = () => {
  const [redirect, setRedirect] = useState(null);
  const {state} = useContext(StoreContext);

  i18n({language: state.language});

  const getPermalink = pageId => state.pages.filter(page => page.id === pageId).map(page => getLanguageDependendPermalink(page)).pop();

  return redirect ? 
    <Redirect to={redirect} /> : (
    <React.Fragment>
      <footer className="container-fluid mt-5 bg-info bg-gradient border-top text-center">
        <div className="container py-lg-5">
          <div className="row align-items-center">
            <div className="col-12 col-lg-2 text-center text-lg-start">
              <img src="/assets/logos/brainority-software-gmbh-logo-weiss.svg" width="200" height="95" alt="Brainority Software GmbH Logo in Weiss" style={{width:200}} onClick={() => setRedirect('/')} />
            </div>
            <div className="col-12 col-lg-10 text-start text-lg-end my-3 mt-lg-0">
              {(process.env.NODE_ENV === 'development' || window.location.host.includes('.intra.')) ? <Link className="d-block d-lg-inline me-2 mb-2 mb-lg-0 text-dark text-decoration-none pe-2" to="/uploads">Uploads</Link> : null}
              <Link className="d-block d-lg-inline me-2 text-dark text-decoration-none pe-2 mb-2 mb-lg-0" to="/sitemap">Sitemap</Link>
              {state.navigationFooter.length > 0 ? state.navigationFooter.map(item => 
                <Link className="d-block d-lg-inline me-2 text-dark text-decoration-none pe-2 mb-2 mb-lg-0" key={item.id} to={getPermalink(item.page.id)}>
                  {getLanguageDependendContent(item, 'name')}
                </Link>
              ) : null}
              <a href="https://www.linkedin.com/company/brainority/?viewAsMember=true" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin fa-xl me-2" /></a>
              <a href="https://x.com/brainority" target="_blank" rel="noreferrer"><i className="fa-brands fa-square-x-twitter fa-xl me-2" /></a>
              <a href="https://www.youtube.com/@brainority" target="_blank" rel="noreferrer"><i className="fa-brands fa-square-youtube fa-xl" /></a>
            </div>
          </div>
        </div>
      </footer>
      {(process.env.NODE_ENV === 'development' || window.location.host.includes('.intra.')) ? <div className="container-fluid bg-danger">
        <div className="row">
          <div className="col text-white text-center p-2">
            Version: #{process.env.REACT_APP_VERSION}
          </div>
        </div>
      </div> : null}
    </React.Fragment>
  )

}

export default Footer;